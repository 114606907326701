import AppContext from "src/context";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { AllBotsStore } from "./AllBots";
import { CEXBotStore } from "./CEX/CEXBot";
import { DEXBotStore } from "./DEX/DEXBot";
import { GoogleAuthStore } from "./GoogleAuth";
import { UserStore } from "./User";
import { ChainsInfoStore, IChainsInfoProvider } from "./chain/ChainsInfo";
import { AbilitiesStore } from "./shared/AbilitiesStore";
import { UserConnection } from "./shared/UserConnection";
import { ITimezoneStore, TimezoneStore } from "./time/TimezoneStore";

const chainsStore = new ChainsInfoStore() as IChainsInfoProvider;

const userStore = new UserStore();

const userConnection = new UserConnection(userStore);

const abilitiesStore = new AbilitiesStore({ abilitiesProvider: userStore });

export const appState = {
  allBotsState: new AllBotsStore(chainsStore),
  userState: userStore,
  abilitiesState: abilitiesStore,
  CEXBotState: new CEXBotStore(),
  DEXBotState: new DEXBotStore(),
  chainsInfoState: chainsStore,
  userConnectionState: userConnection,
  googleAuthState: new GoogleAuthStore(),
  timezone: new TimezoneStore() as ITimezoneStore,
};

export type AppState = typeof appState;

export const useAppState = (): AppState => useLateInitContext(AppContext);
