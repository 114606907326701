import { ApiModules, apiUrl } from "src/api";
import { buildQueryUrl } from "src/helpers/url";
import { DEXV2BotMode } from "src/modules/bots";
import { Note } from "src/modules/shared";
import ResponseHandler from "src/state/network/ResponseHandler";
import { StatsWalletType } from "./types";

export interface StatsWallet {
  id: string;
  address: string;
  type: StatsWalletType;
  balance: number;
}

export interface GetBotWalletResponse {
  id: string;
  addr: string;
}

export interface GetBotWalletsResponse {
  deployer: GetBotWalletResponse;
  volume: GetBotWalletResponse[];
  limit: GetBotWalletResponse[];
  counter: GetBotWalletResponse[];
  withdrawers?: string[];
}

export type GetBotWalletsRequestType = "volume" | "limit" | "withdrawer" | "counter";

export const getBotWallets = async (deployerId: string, type?: GetBotWalletsRequestType) => {
  const queryParams = type ? { type } : undefined;
  const url = buildQueryUrl(`/${deployerId}/wallets`, queryParams);
  return ResponseHandler.handler<GetBotWalletsResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, url),
    method: "GET",
  });
};

export interface GetBalancesRequest {
  deployer_id?: string;
  volume_executors?: string[];
  limit_executors?: string[];
  counter_executors?: string[];
}
export interface GetBalanceResponse {
  key_id: string;
  balance: number;
}

export interface GetBalancesResponse {
  deployer_balance: number;
  limit_executors: GetBalanceResponse[] | null;
  volume_executors: GetBalanceResponse[] | null;
  counter_executors: GetBalanceResponse[] | null;
}

export const getBalances = async (deployerId: string, data: GetBalancesRequest) =>
  ResponseHandler.handler<GetBalancesResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${deployerId}/wallet/balance`),
    method: "PATCH",
    data,
  });

export interface WithdrawNativeRequest {
  deployer_id?: string;
  volume_executors?: string[];
  limit_executors?: string[];
  counter_executors?: string[];
  receiver: string;
  amount?: number;
}

export interface WalletTransactionResponse {
  hash: string;
  id: string;
  message?: string;
}

export interface WithdrawNativeResponse {
  deployer_hash?: Omit<WalletTransactionResponse, "id">;
  limit_hash?: WalletTransactionResponse[];
  volume_hash?: WalletTransactionResponse[];
  counter_hash?: WalletTransactionResponse[];
}

export const withdrawNative = async (deployerId: string, data: WithdrawNativeRequest) =>
  ResponseHandler.handler<WithdrawNativeResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${deployerId}/native/withdraw`),
    method: "POST",
    data,
  });

export interface DistributeNativeRequest {
  volume_executors?: string[];
  limit_executors?: string[];
  counter_executors?: string[];
  amount: number;
}

export interface DistributeNativeResponse {
  hash: string;
}

export const distributeNative = async (deployerId: string, data: DistributeNativeRequest) =>
  ResponseHandler.handler<DistributeNativeResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `${deployerId}/native/distribute`),
    method: "POST",
    data,
  });

export enum DEXV2VaultTypeOrder {
  Main = 0,
  Volume = 1,
  Limit = 2,
  Counter = 4,
}

export enum DEXV2VaultTokenOrder {
  Base = 0,
  Quote = 1,
}

export interface TransferPermissionRequest {
  sender: string;
  token: DEXV2VaultTokenOrder;
  from_vault: DEXV2VaultTypeOrder;
  to_vault: DEXV2VaultTypeOrder;
  amount: string;
}

export interface PermissionResponse {
  expire: number;
  nonce: number;
  signature: string;
}

export interface TransferPermissionResponse extends PermissionResponse {}

export const getTransferPermission = async (deployerId: string, data: TransferPermissionRequest) =>
  ResponseHandler.handler<TransferPermissionResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${deployerId}/perm/transfer`),
    method: "PATCH",
    data,
  });

export const setNotes = async (botId: string, note: Note) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/note`),
    method: "PUT",
    data: { bot_id: botId, ...note },
  });

export const getNotes = async (botId: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/note`),
    method: "GET",
  });

export interface RangeQueryParams {
  start: string;
  end: string;
}

export interface GetPNLRequestParams extends RangeQueryParams {}

type BotModeTotalKeys = `${DEXV2BotMode}_bot_total`;

export type PNLValueType = "base" | "native" | "quote";

export type BotModesTotal = Record<BotModeTotalKeys, number>;

export type PNLByBotType = Record<PNLValueType, BotModesTotal>;

export interface GetPNLResponse {
  base: number;
  native: number;
  price: number;
  quote: number;
  value_by_bot_type: PNLByBotType;
}

export const getPNL = async (deployerId: string, params?: GetPNLRequestParams) => {
  const url = buildQueryUrl(`/${deployerId}/history/pnl`, params);
  return ResponseHandler.handler<GetPNLResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, url),
    method: "GET",
  });
};
