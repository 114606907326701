import { makeAutoObservable } from "mobx";
import { getSupportedChains } from "src/api/bots/DEXV2/chain";
import { arrayToMapObj } from "src/helpers/array";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { supportedChainsRespToChainInfos } from "./mappers";
import { ChainInfo, ChainInfosMap } from "./types";

export const getScannerLink = (info: ChainInfo | null, type: "tx" | "wallet") => {
  if (!info) return undefined;
  const { addressScanner, txScanner } = info;
  switch (type) {
    case "tx": {
      return txScanner;
    }
    case "wallet": {
      return addressScanner;
    }
  }
};

export interface IChainsProvider {
  get chains(): ChainInfosMap;
  getChains: () => Promise<void>;
}

export class ChainsStore implements IChainsProvider, IDisposable {
  private _chains: ChainInfo[] | null = null;

  private _loading = false;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { chains: true });
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private get _chainsOrEmpty() {
    return this._chains ?? [];
  }

  get chains(): ChainInfosMap {
    return arrayToMapObj(this._chainsOrEmpty, "id");
  }

  private _setChains = (chains: ChainInfo[]) => {
    this._chains = chains;
  };

  getChains = async () => {
    await this._getChains();
  };

  private _getChains = async () => {
    if (this._loading || this._chains) return;

    this._setLoading(true);

    try {
      const { isError, data } = await getSupportedChains();
      if (!isError) {
        const chains = supportedChainsRespToChainInfos(data);
        this._setChains(chains);
      }
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
