import { Price, Token } from "@uniswap/sdk-core";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { ITradePairPriceProvider } from "src/state/DEXV2/Providers/TradePairUSDPriceProvider";
import { CacheOptions } from "../../../shared";

export interface IBaseUSDPriceProvider extends IDisposable {
  get baseUSDPrice(): Price<Token, Token> | undefined;
  getBaseUSDPrice(options?: CacheOptions): Promise<void>;
  get canQuery(): boolean;
}

export interface IBaseUSDPriceParams {
  tradePairPriceProvider: ITradePairPriceProvider;
}
export class BaseUSDPriceProvider implements IBaseUSDPriceProvider {
  private _tradePairPriceProvider: ITradePairPriceProvider;

  constructor({ tradePairPriceProvider }: IBaseUSDPriceParams) {
    makeAutoObservable(this);

    this._tradePairPriceProvider = tradePairPriceProvider;

    makeLoggable(this, { baseUSDPrice: true });
  }

  private get _pairPrice() {
    return this._tradePairPriceProvider.pairPrice;
  }

  get baseUSDPrice(): Price<Token, Token> | undefined {
    const pairPrice = this._pairPrice;
    if (!pairPrice) {
      return undefined;
    }

    const {
      base: { usd: baseUsdPrice },
    } = pairPrice;

    return baseUsdPrice;
  }

  get canQuery() {
    return this._tradePairPriceProvider.canQuery;
  }

  async getBaseUSDPrice(options: CacheOptions = {}) {
    try {
      await this._tradePairPriceProvider.getTradePairPrice(options);
    } catch (err) {
      logError(err);
    }
  }

  destroy = () => {};
}
