import { ChildrenProps } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { TradePairQuotesCalculator } from "src/state/DEXV2/Providers/TradePairQuotesCalculator";
import { TradePairQuotesContext } from "../TradePairQuotesContext";
import { DEXV2BotContext } from "./DEXV2Bot";

type DEXV2BotQuotesProviderProps = ChildrenProps;

export const DEXV2BotQuotesProvider = ({ children }: DEXV2BotQuotesProviderProps) => {
  const botsState = useLateInitContext(DEXV2BotContext);

  const quotesState = useLocalStore(TradePairQuotesCalculator, {
    tradePairPriceProvider: botsState.tradePairPriceProvider,
    tradePairProvider: botsState.tradePairProvider,
  });

  return (
    <TradePairQuotesContext.Provider value={quotesState}>
      {children}
    </TradePairQuotesContext.Provider>
  );
};
