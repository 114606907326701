import dayjs, { Dayjs } from "dayjs";
import { DEFAULT_FULL_DATE_FORMAT } from "src/helpers/dateUtils";
import { UseDatePickerProps } from "../../DatePicker";
import { DatePickerRange } from "../models/datePicker";
import { DateTimeRange } from "../models/dateTimeRange";
import { DateRange } from "../models/range";

const getUtcOffset = () => dayjs().utcOffset();

export const getDayjsNow = (useUtc?: boolean) => {
  const now = useUtc ? dayjs.utc() : dayjs();
  return now;
};

const dayjsToLocalDate = (dayjsDate: Dayjs) => {
  const offset = getUtcOffset();
  return dayjsDate.subtract(offset, "minute").toDate();
};

const localDateToDayjs = (date: Date) => {
  const dayjsDate = dayjs(date);
  const offset = getUtcOffset();
  return dayjsDate.add(offset, "minute").utc();
};

interface DayjsToDateOptions {
  useUtc?: boolean;
}

const dayjsToDate = (dayjsDate: Dayjs | null, { useUtc }: DayjsToDateOptions = {}) => {
  if (!dayjsDate) return null;
  if (!dayjsDate.isValid()) return null;
  const date = useUtc ? dayjsToLocalDate(dayjsDate) : dayjsDate.toDate();
  return date;
};

interface DateToDayjsOptions {
  useUtc?: boolean;
}

const dateToDayjs = (date: Date | null, { useUtc }: DateToDayjsOptions = {}) => {
  if (!date) return null;
  const dayjsDate = useUtc ? localDateToDayjs(date) : dayjs(date);

  return dayjsDate;
};

const isSameDay = (date1: Dayjs, date2: Dayjs) => date1.diff(date2, "day") === 0;

const copyTime = (from: Dayjs, to: Dayjs) =>
  to
    .set("hour", from.hour())
    .set("minute", from.minute())
    .set("second", from.second())
    .set("millisecond", from.millisecond());

interface DateToRangeDayjsOptions
  extends DateToDayjsOptions,
    Pick<UseDatePickerProps, "minDate" | "maxDate" | "disableFuture"> {}

// corrects dayjs date to min/max/future constraints on top of dateToDayjs
const dateToRangeDayjs = (
  date: Date | null,
  { useUtc, minDate, maxDate, disableFuture }: DateToRangeDayjsOptions = {}
) => {
  const dayjsDate = dateToDayjs(date, { useUtc });

  if (!dayjsDate) return null;

  const now = getDayjsNow(useUtc);

  if (minDate && isSameDay(dayjsDate, minDate)) {
    return copyTime(minDate, dayjsDate);
  }

  if (maxDate && isSameDay(dayjsDate, maxDate)) {
    return copyTime(maxDate, dayjsDate);
  }

  if (disableFuture && dayjsDate.isAfter(now)) {
    return copyTime(now, dayjsDate);
  }

  return dayjsDate;
};

interface DayjsRangeToPickerRangeOptions extends DayjsToDateOptions {}

export const dayjsRangeToPickerRange = (
  range: DateRange<Dayjs>,
  options: DayjsRangeToPickerRangeOptions = {}
): DatePickerRange => {
  const dateRange = range.map((date) => dayjsToDate(date, options)) as DatePickerRange;
  return dateRange;
};

export interface PickerRangeToDayjsRangeOptions extends DateToRangeDayjsOptions {}

export const pickerRangeToDayjsRange = (
  range: DatePickerRange,
  options: PickerRangeToDayjsRangeOptions = {}
): DateRange<Dayjs> => {
  const dateRange = range.map((date) => dateToRangeDayjs(date, options)) as DateRange<Dayjs>;
  return dateRange;
};

const EMPTY_RANGE_TEXT = "--:--";

export const rangeToText = (
  [start, end]: DateRange<Dayjs>,
  formatStr = DEFAULT_FULL_DATE_FORMAT
) => {
  const startText = start?.format(formatStr);
  const endText = end?.format(formatStr);
  if (!startText || !endText) return EMPTY_RANGE_TEXT;
  return `${startText} - ${endText}`;
};

export const dayRangeToDiff = ([start, end]: DateTimeRange): number | null => {
  if (!start || !end) return null;
  return start.diff(end, "seconds");
};
