import { makeAutoObservable } from "mobx";
import { getCEXBot } from "src/api/bots/CEX/bots";
import { getLiquiditySettings } from "src/api/bots/CEX/liquidity";
import { getMultiGridUUID, getMultiGrinderStatus } from "src/api/bots/CEX/multigrinder";
import { CEXRoutes } from "src/app/constants";
import { IndicationName } from "src/components/NavigationMenu/MenuContent/shared/MenuList/MenuItem/types";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { stringDateToUnix } from "src/helpers/dateUtils";
import { ColorStatuses } from "src/helpers/getStatus/getStatusColor";
import { geCexSettingsMsg, getLastUpdateMsg } from "./utils";
import { mapStatusToNumber } from "../shared/utils/statusMapper";

export interface InfoIndication {
  status: ColorStatuses | null;
  message?: string;
}

interface LoadIndicationProps {
  allIndicationInCurrentMenu: IndicationName[];
  party?: string;
  uuid?: string;
  currentTab?: string;
}

interface ShouldFetchDataProps {
  indicationKey: IndicationName;
  currentIdentifier?: string;
  lastIdentifier?: string;
  currentTab?: string;
  skipTab: string;
  allIndicationInCurrentMenu: IndicationName[];
}

interface IndicationMap extends Partial<Record<IndicationName, InfoIndication>> {}

export class NavigationMenuStore implements IDisposable {
  private _indicationMap: IndicationMap = {};

  private _lastBotId?: string;

  private _lastParty?: string;

  constructor() {
    makeAutoObservable(this);
  }

  get indicationMap() {
    return this._indicationMap;
  }

  getIndication = (indicationName?: IndicationName) =>
    indicationName && this.indicationMap?.[indicationName];

  loadIndication = ({
    allIndicationInCurrentMenu,
    party,
    uuid,
    currentTab,
  }: LoadIndicationProps) => {
    if (
      this._shouldFetchData({
        indicationKey: "cexSettings",
        currentIdentifier: uuid,
        lastIdentifier: this._lastBotId,
        currentTab,
        skipTab: CEXRoutes.Settings,
        allIndicationInCurrentMenu,
      })
    )
      this._fetchCexSettings(uuid as string);

    if (
      this._shouldFetchData({
        indicationKey: "cexGrid",
        currentIdentifier: uuid,
        lastIdentifier: this._lastBotId,
        currentTab,
        skipTab: CEXRoutes.Grid,
        allIndicationInCurrentMenu,
      })
    )
      this._fetchCexGrid(uuid as string);

    if (
      this._shouldFetchData({
        indicationKey: "multigrid",
        currentIdentifier: party,
        lastIdentifier: this._lastParty,
        currentTab,
        skipTab: CEXRoutes.Multigrid,
        allIndicationInCurrentMenu,
      })
    )
      this._fetchMultiGrid(party as string);

    this._updateLastLayerInfo(party, uuid);
  };

  private _shouldFetchData = ({
    indicationKey,
    currentIdentifier,
    lastIdentifier,
    currentTab,
    skipTab,
    allIndicationInCurrentMenu,
  }: ShouldFetchDataProps) => {
    const isIndicationInCurrentMenu = allIndicationInCurrentMenu.includes(indicationKey);

    if (!isIndicationInCurrentMenu) {
      this._setIndicationMap(indicationKey);
      return false;
    }

    const hasData = Boolean(this.indicationMap[indicationKey]);

    return (
      currentIdentifier &&
      (!hasData || currentIdentifier !== lastIdentifier) &&
      currentTab !== skipTab
    );
  };

  private _updateLastLayerInfo = (party?: string, uuid?: string) => {
    this._lastBotId = uuid;
    this._lastParty = party;
  };

  private _setIndicationMap = (key: IndicationName, value?: InfoIndication) => {
    this._indicationMap[key] = value;
  };

  private _fetchCexSettings = async (uuid: string) => {
    try {
      const { isError, data } = await getCEXBot(uuid);

      if (!isError) {
        const { status, timeDontWork, message } = data.bot || {};
        const msg = geCexSettingsMsg(timeDontWork, message);

        this._setIndicationMap("cexSettings", { status, message: msg });
      }
    } catch (error) {
      logError(error);
    }
  };

  private _fetchCexGrid = async (uuid: string) => {
    try {
      const { data, isError } = await getLiquiditySettings(uuid);

      if (!isError) {
        const { state, updatedAt } = data[0] || {};

        this._setIndicationMap("cexGrid", {
          status: mapStatusToNumber(state),
          message: updatedAt && getLastUpdateMsg(stringDateToUnix(updatedAt)),
        });
      }
    } catch (error) {
      logError(error);
    }
  };

  private _fetchMultiGrid = async (party: string) => {
    const multiGrinderId = await this._fetchMultiGrinderId(party);

    if (multiGrinderId) this._fetchMultiGridStatus(multiGrinderId);
    else this._setIndicationMap("multigrid", { status: null });
  };

  private _fetchMultiGrinderId = async (party: string) => {
    try {
      const { isError, data } = await getMultiGridUUID(party);

      if (!isError) return data[0];
    } catch (error) {
      logError(error);
    }
  };

  private _fetchMultiGridStatus = async (uuid: string) => {
    try {
      const { isError, data } = await getMultiGrinderStatus(uuid);

      if (!isError) {
        const { status, lastUpdateAt } = data;

        this._setIndicationMap("multigrid", {
          status: mapStatusToNumber(status),
          message: getLastUpdateMsg(lastUpdateAt),
        });
      }
    } catch (error) {
      logError(error);
    }
  };

  destroy = () => {};
}
