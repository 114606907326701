import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { AllBotsContext } from "src/context/AllBots";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { BotsType } from "src/modules/bots";
import { useQueryUrlParams } from "src/hooks/useQueryUrlParams";
import { useSubscribableStore } from "src/hooks/useSubscribableStore";
import { ErrorBoundary } from "../shared/ErrorBoundary";
import { Aside } from "./Aside";
import { CEXBots } from "./Bots/CEXBots";
import { DEXBots } from "./Bots/DEXBots";
import { DEXV2Bots } from "./Bots/DEXV2Bots";
import * as styles from "./style";

const getBotsByType = (type: BotsType) => {
  const bots = {
    CEX: <CEXBots />,
    DEX: <DEXBots />,
    DEXV2: <DEXV2Bots />,
  };

  return bots[type];
};

export const AllBots = observer(() => {
  const state = useLateInitContext(AllBotsContext);
  const { currentTab } = state;

  const searchParamsProps = useQueryUrlParams();

  useEffect(() => {
    state.setUrlParamsState(searchParamsProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSubscribableStore(state);

  return (
    <styles.Container>
      <Aside />

      <ErrorBoundary fallback={<styles.MainFallback />}>
        <styles.Main>{getBotsByType(currentTab)}</styles.Main>
      </ErrorBoundary>
    </styles.Container>
  );
});
