import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { makeAutoObservable } from "mobx";
import { IDisposable } from "src/helpers/utils";
import { ITradePairProvider } from "../TradePairProvider";
import { ITradePairPriceProvider } from "../TradePairUSDPriceProvider";
import { ITradePairQuotesProvider } from "./types";
import { parseBaseQuote, parseQuoteQuote } from "./utils";

interface ITradePairQuotesProviderParams {
  tradePairPriceProvider: ITradePairPriceProvider;
  tradePairProvider: ITradePairProvider;
}

export class TradePairQuotesCalculator implements IDisposable, ITradePairQuotesProvider {
  private _tradePairPriceProvider: ITradePairPriceProvider;

  private _tradePairProvider: ITradePairProvider;

  constructor({ tradePairPriceProvider, tradePairProvider }: ITradePairQuotesProviderParams) {
    makeAutoObservable(this, { getBasePairQuote: false, getQuotePairQuote: false });

    this._tradePairPriceProvider = tradePairPriceProvider;
    this._tradePairProvider = tradePairProvider;
  }

  private get _pairPrice() {
    return this._tradePairPriceProvider.pairPrice;
  }

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  getBasePairQuote = (amount: CurrencyAmount<Token> | string | number | undefined) =>
    parseBaseQuote({ value: amount, token: this._tradePair?.base, price: this._pairPrice?.base });

  getQuotePairQuote = (amount: CurrencyAmount<Token> | string | number | undefined) =>
    parseQuoteQuote({
      value: amount,
      token: this._tradePair?.quote,
      price: this._pairPrice?.quote,
    });

  destroy = () => {};
}
