import { BotStartButton } from "src/components/AllBots/Bots/shared/BotStartButton";
import styled from "styled-components";
import { Exchange, Frame } from "../../shared/shared";
import { DEXVersionChip } from "../shared/DEXVersionChip";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  :not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export const Favorites = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    cursor: pointer;
  }
`;

export const DEXFrame = styled(Frame)``;

export const DEXV2BotColumns = `
    minmax(150px, 1fr) minmax(120px, 1fr) minmax(150px, 1fr)
    repeat(3, minmax(90px, 1fr))
    70px auto;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${DEXV2BotColumns};

  color: currentColor;
`;

export const CellContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  color: currentColor;
  overflow-y: auto;
`;

export const ButtonCellContent = styled(CellContent)`
  overflow: initial;
`;

export const ModeCellContent = styled(CellContent)`
  overflow: initial;
`;

export const PairCellContent = styled(CellContent)`
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 18px;
  padding-left: 20px;
  gap: 5px;
`;

export const DEXV2Exchange = styled(Exchange)`
  word-break: break-all;
`;

export const ExchangeCellContent = styled(CellContent)`
  gap: 4px;
`;

export const DEXV2Version = styled(DEXVersionChip)`
  font-size: ${({ theme }) => theme.fontSizes["14px"]};

  @media (max-width: 1230px) {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;

export const StartButton = styled(BotStartButton)``;
