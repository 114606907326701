import { ApiModules, apiUrl } from "src/api";
import { buildQueryUrl } from "src/helpers/url";
import { DEXV2ExchangeVersion } from "src/modules/bots";
import ResponseHandler from "src/state/network/ResponseHandler";

export type GetBotTotalStatus = "error" | "warning" | "stale" | "running" | "stopped";

export type GetBotModeStatus = "stopped" | "error" | "running";
export interface GetBotStatusResponse {
  quote_ticker: string;
  base_ticker: string;
  quote_addr: string;
  base_addr: string;
  pair_addr: string;
  bot_id: string;
  bot_name: string;
  exchange: string;
  party: string;
  chain_id: number;
  ref: string;
  deployed: boolean;
  dex_version: DEXV2ExchangeVersion;

  volume_status: boolean;
  volume_descr: GetBotModeStatus;
  volume_bot_last_trade_date: string;
  volume_bot_last_trade_date_unix: number;
  volume_bot_last_trade_status: string;
  volume_bot_next_trade_date: string;
  volume_bot_next_trade_date_unix: number;

  limit_status: boolean;
  limit_descr: GetBotModeStatus;
  limit_bot_last_trade_date: string;
  limit_bot_last_trade_date_unix: number;
  limit_bot_last_trade_status: string;
  limit_bot_next_trade_date: string;
  limit_bot_next_trade_date_unix: number;

  counter_status: boolean;
  counter_descr: GetBotModeStatus;
  counter_bot_message?: string;
  counter_bot_last_trade_date_unix?: number;
  counter_bot_last_trade_status?: string;

  total_status: GetBotTotalStatus;
}

export const getBotStatus = async (botId: string) =>
  ResponseHandler.handler<GetBotStatusResponse[]>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/status`),
      method: "GET",
    },
    `getDEXV2BotStatus/${botId}`
  );

export const getBotStatuses = async (party?: string) => {
  const url = buildQueryUrl("/status", { party });

  return ResponseHandler.handler<GetBotStatusResponse[]>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, url),
      method: "GET",
    },
    "getDEXV2BotStatuses"
  );
};

export interface GetWatchListResponse {
  bots: string[];
}

export const getWatchList = async () =>
  ResponseHandler.handler<GetWatchListResponse>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, "/watchlist"),
      method: "GET",
    },
    "getDEXV2WatchList"
  );

export const deleteWatchList = async (bots: string[]) =>
  ResponseHandler.handler<{}>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, "/watchlist"),
      method: "DELETE",
      data: {
        bots,
      },
    },
    "deleteDEXV2WatchList"
  );

export const addWatchList = async (bots: string[]) =>
  ResponseHandler.handler<{}>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, "/watchlist"),
      method: "PUT",
      data: {
        bots,
      },
    },
    "addDEXV2WatchList"
  );

export type GetBasesResponse = string[];

export const getBases = async (party?: string) => {
  const url = buildQueryUrl("/base", { party });

  return ResponseHandler.handler<GetBasesResponse>(
    {
      url: apiUrl(ApiModules.DEXV2Bots, url),
      method: "GET",
    },
    "getBasesDEXV2"
  );
};

export interface GetExchangesResponse extends GetBasesResponse {}

export const getExchanges = async (party?: string) => {
  const url = buildQueryUrl("/exchange", { party });

  return ResponseHandler.handler<GetExchangesResponse>(
    {
      url: apiUrl(ApiModules.DEXV2Bots, url),
      method: "GET",
    },
    "getExchangesDEXV2"
  );
};

export type GetChainsResponse = number[];

export const getChains = async () =>
  ResponseHandler.handler<GetChainsResponse>(
    {
      url: apiUrl(ApiModules.DEXV2Bots, "/chain"),
      method: "GET",
    },
    "getChainsDEXV2"
  );
