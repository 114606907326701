import { ApiModules, apiUrl } from "src/api";
import { DEXV2BotVersion, DEXV2ExchangeVersion } from "src/modules/bots";
import ResponseHandler from "src/state/network/ResponseHandler";

export interface SupportedChain {
  chain_id: number;
  rpc: string;
  scanner: string;
  symb: string;
  chain_name: string;
  dexscreener_alias: string;
}

export interface GetSupportedChainsResponse {
  chains: SupportedChain[];
}

export const getSupportedChains = async () =>
  ResponseHandler.handler<GetSupportedChainsResponse>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, "chains"),
      method: "GET",
    },
    "getSupportedChainsDEXV2"
  );

export interface SupportedExchange {
  factory: string;
  router: string;
  dex_version: DEXV2ExchangeVersion;
  pool_percent: number[];
  dex_id: number;
  version: DEXV2BotVersion;
}

export type SupportedExchanges = Record<number, Record<string, SupportedExchange>>;

export interface GetSupportedExchangesResponse extends SupportedExchanges {}

export const getSupportedExchanges = async () =>
  ResponseHandler.handler<GetSupportedExchangesResponse>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, "/exchange"),
      method: "GET",
    },
    "getSupportedExchangesDEXV2"
  );

interface BackendHelperConfigResponse {
  version: DEXV2BotVersion;
  addr: string;
}

export interface ChainsConfigResponse {
  abbr: string;
  address_helper: string;
  backend_helper: BackendHelperConfigResponse[];
  balance_helper: string;
  price_feed_addr: string;
  chain_id: number;
  colour: string;
  pic: string;
}

export type GetChainsConfigsResponse = ChainsConfigResponse[];

export const getChainsConfig = async () =>
  ResponseHandler.handler<GetChainsConfigsResponse>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, "/front/settings"),
      method: "GET",
    },
    "getChainsConfig"
  );

export interface ChainQuoterResponse {
  chain_id: number;
  dex_addr: string;
  quoter_addr: string;
}

export type GetChainsQuotersResponse = ChainQuoterResponse[];

export const getChainsQuoters = async () =>
  ResponseHandler.handler<GetChainsQuotersResponse>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, "/front/quoters"),
      method: "GET",
    },
    "getChainsQuoters"
  );

export interface GetLatestBotVersionResponse {
  version: DEXV2BotVersion;
}

export const getLatestBotVersion = async () =>
  ResponseHandler.handler<GetLatestBotVersionResponse>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, "/version"),
      method: "GET",
    },
    "getLatestBotVersion"
  );
