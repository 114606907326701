import { useContext, useMemo } from "react";
import { Theme, darkTheme, lightTheme } from "src/components/Themes";
import { ThemeContext } from "styled-components";
import { isDefined } from "../utils";

export const getTheme = (): Theme =>
  localStorage.getItem("theme") === "light" ? lightTheme : darkTheme;

export const getColor = (status: number, timeDontWork: number, modifyVolume: boolean): string => {
  const themes = getTheme();

  const { gray } = themes.botColorStatus;
  const { green } = themes.botColorStatus;
  const { yellow } = themes.botColorStatus;
  const { blue } = themes.botColorStatus;
  const dark_red = themes.botColorStatus.darkRed;
  const light_red = themes.botColorStatus.lightRed;
  const { purple } = themes.botColorStatus;

  if (modifyVolume) {
    return purple;
  }
  if (status === 4) {
    return gray;
  }
  if (status === 3) {
    return green;
  }
  if (status === 1) {
    return yellow;
  }
  if (status === 2) {
    return blue;
  }
  if (status === 0 && timeDontWork <= 300) {
    return light_red;
  }
  if (status === 0 && timeDontWork > 300) {
    return dark_red;
  }

  return gray;
};

export const ColorStatusesMap = {
  Red: 0,
  Yellow: 1,
  Blue: 2,
  Green: 3,
  Gray: 4,
} as const;

export type ColorStatuses = (typeof ColorStatusesMap)[keyof typeof ColorStatusesMap];

export const useColorStatus = () => {
  const { botColorStatus } = useContext(ThemeContext);

  const colorMapper = useMemo(
    () =>
      (status: ColorStatuses | null, timeDontWork?: number): string => {
        switch (status) {
          case ColorStatusesMap.Red: {
            if (!isDefined(timeDontWork)) return botColorStatus.darkRed;

            if (timeDontWork > 300) return botColorStatus.darkRed;

            return botColorStatus.lightRed;
          }
          case ColorStatusesMap.Yellow: {
            return botColorStatus.yellow;
          }
          case ColorStatusesMap.Blue: {
            return botColorStatus.blue;
          }
          case ColorStatusesMap.Green: {
            return botColorStatus.green;
          }
          case ColorStatusesMap.Gray: {
            return botColorStatus.gray;
          }
          default: {
            return botColorStatus.gray;
          }
        }
      },
    [botColorStatus]
  );

  return colorMapper;
};

export const useBgColorStatus = () => {
  const { botBgColorStatus } = useContext(ThemeContext);

  const colorMapper = useMemo(
    () =>
      (status: number, timeDontWork: number): string => {
        switch (status) {
          case 0: {
            if (timeDontWork > 300) {
              return botBgColorStatus.darkRed;
            }

            return botBgColorStatus.lightRed;
          }
          case 1: {
            return botBgColorStatus.yellow;
          }
          case 2: {
            // return botBgColorStatus.gray;
            return botBgColorStatus.blue;
          }
          case 3: {
            return botBgColorStatus.green;
          }
          case 4: {
            // return botBgColorStatus.blue;
            return botBgColorStatus.gray;
          }
          default: {
            return botBgColorStatus.gray;
          }
        }
      },
    [botBgColorStatus]
  );

  return colorMapper;
};

export const useBgSideColorStatus = () => {
  const { botBgSideColorStatus } = useContext(ThemeContext);

  const colorMapper = useMemo(
    () =>
      (status: number, timeDontWork: number): string => {
        switch (status) {
          case 0: {
            if (timeDontWork > 300) {
              return botBgSideColorStatus.darkRed;
            }

            return botBgSideColorStatus.lightRed;
          }
          case 1: {
            return botBgSideColorStatus.yellow;
          }
          case 2: {
            // return botBgSideColorStatus.gray;
            return botBgSideColorStatus.blue;
          }
          case 3: {
            return botBgSideColorStatus.green;
          }
          case 4: {
            // return botBgSideColorStatus.blue;
            return botBgSideColorStatus.gray;
          }
          default: {
            return botBgSideColorStatus.green;
          }
        }
      },
    [botBgSideColorStatus]
  );

  return colorMapper;
};
