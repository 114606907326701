import {
  Currency,
  CurrencyAmount,
  Percent,
  Price,
  Token,
  TradeType,
  computePriceImpact,
} from "@uniswap/sdk-core";
import {
  ONE_HUNDRED_PERCENT,
  ZERO_PERCENT,
  calculateNextPriceImpact,
} from "src/state/DEXV2/DEXV2Swap/shared";
import invariant from "tiny-invariant";
import { V3LensRoute, validatePrice } from "./V3LensRoute";

interface IV3LensTradeParams<
  TInput extends Currency,
  TOutput extends Currency,
  TTradeType extends TradeType,
> {
  inputAmount: CurrencyAmount<TInput>;
  outputAmount: CurrencyAmount<TOutput>;
  tradeType: TTradeType;
  route: V3LensRoute<TInput, TOutput>;
  nextMidPrice: Price<TInput, TOutput> | undefined;
}

const validateSlippageTolerance = (slippageTolerance: Percent) => {
  invariant(!slippageTolerance.lessThan(ZERO_PERCENT), "SLIPPAGE_TOLERANCE");
};

export class V3LensTrade<
  TInput extends Currency,
  TOutput extends Currency,
  TTradeType extends TradeType,
> {
  private _executionPrice: Price<TInput, TOutput> | undefined;

  private _nextPriceImpact: Percent | null = null;

  readonly inputAmount: CurrencyAmount<TInput>;

  readonly outputAmount: CurrencyAmount<TOutput>;

  readonly tradeType: TradeType;

  readonly path: Token[];

  readonly priceImpact: Percent | undefined;

  readonly nextMidPrice: Price<TInput, TOutput> | undefined;

  readonly route: V3LensRoute<TInput, TOutput>;

  constructor({
    inputAmount,
    outputAmount,
    tradeType,
    route,
    nextMidPrice,
  }: IV3LensTradeParams<TInput, TOutput, TTradeType>) {
    if (nextMidPrice) {
      validatePrice(inputAmount.currency, outputAmount.currency, nextMidPrice);
    }

    this.inputAmount = inputAmount;
    this.outputAmount = outputAmount;
    this.tradeType = tradeType;
    this.nextMidPrice = nextMidPrice;
    this.path = route.tokenPath;
    this.route = route;
    this.priceImpact = route.midPrice
      ? computePriceImpact(route.midPrice, this.inputAmount, this.outputAmount)
      : undefined;
  }

  get executionPrice(): Price<TInput, TOutput> {
    const executionPrice =
      this._executionPrice ??
      new Price(
        this.inputAmount.currency,
        this.outputAmount.currency,
        this.inputAmount.quotient,
        this.outputAmount.quotient
      );
    this._executionPrice = executionPrice;

    return executionPrice;
  }

  get nextPriceImpact() {
    if (!this.nextMidPrice) {
      return null;
    }

    if (this._nextPriceImpact) {
      return this._nextPriceImpact;
    }

    const { route } = this;

    const { midPrice } = route;

    if (!midPrice) {
      return null;
    }

    const nextPriceImpact = calculateNextPriceImpact(midPrice, this.nextMidPrice);

    this._nextPriceImpact = nextPriceImpact;

    return nextPriceImpact;
  }

  /**
   * Get the minimum amount that must be received from this trade for the given slippage tolerance
   * @param slippageTolerance The tolerance of unfavorable slippage from the execution price of this trade
   * @returns The amount out
   */
  public minimumAmountOut(
    slippageTolerance: Percent,
    amountOut = this.outputAmount
  ): CurrencyAmount<TOutput> {
    validateSlippageTolerance(slippageTolerance);

    if (this.tradeType === TradeType.EXACT_OUTPUT) {
      return amountOut;
    }

    const slippageAdjustedAmountOut = ONE_HUNDRED_PERCENT.add(slippageTolerance)
      .invert()
      .multiply(amountOut.quotient).quotient;
    return CurrencyAmount.fromRawAmount(amountOut.currency, slippageAdjustedAmountOut);
  }

  /**
   * Get the maximum amount in that can be spent via this trade for the given slippage tolerance
   * @param slippageTolerance The tolerance of unfavorable slippage from the execution price of this trade
   * @returns The amount in
   */
  public maximumAmountIn(
    slippageTolerance: Percent,
    amountIn = this.inputAmount
  ): CurrencyAmount<TInput> {
    validateSlippageTolerance(slippageTolerance);

    if (this.tradeType === TradeType.EXACT_INPUT) {
      return amountIn;
    }

    const slippageAdjustedAmountIn = ONE_HUNDRED_PERCENT.add(slippageTolerance).multiply(
      amountIn.quotient
    ).quotient;
    return CurrencyAmount.fromRawAmount(amountIn.currency, slippageAdjustedAmountIn);
  }
}
