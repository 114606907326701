import { ColorStatuses, ColorStatusesMap } from "src/helpers/getStatus/getStatusColor";
import { LiquidityGridStatus } from "src/modules/liquidityGrid";
import { ExchangeModuleStatus } from "src/state/CEX/multiGrid/AddModuleModal";
import { MultiGridStatus } from "src/state/CEX/shared/types";

type StateStatuses = LiquidityGridStatus | MultiGridStatus | ExchangeModuleStatus;

const statusMapper: Record<StateStatuses, ColorStatuses> = {
  stopped: ColorStatusesMap.Gray,
  waiting: ColorStatusesMap.Yellow,
  warning: ColorStatusesMap.Yellow,
  failed: ColorStatusesMap.Red,
  active: ColorStatusesMap.Green,
  initial: ColorStatusesMap.Gray,
};

export const mapStatusToNumber = (status?: StateStatuses) => {
  if (!status) return null;

  const mappedStatus = statusMapper[status];

  return mappedStatus ?? ColorStatusesMap.Gray;
};
