import { observer } from "mobx-react-lite";
import { MainPagesAbilities, MainRoutes } from "src/app/constants";
import { getRoutePath } from "src/components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { PermissionWrapper } from "src/components/shared/PermissionWrapper";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { useTogglePrimaryColor } from "src/hooks/useTogglePrimaryColor";
import { MenuItem } from "./MenuItem";
import { SubMenu } from "./SubMenu";
import * as styles from "./style";

const BUG_REPORT_LINK = "https://app.clickup.com/9015466131/v/b/6-901504522902-2";

const useCreateMenu = () => {
  const CEXCreate = (
    <PermissionWrapper key="CEX" abilityName={MainPagesAbilities.CEXCreatingView}>
      <MenuItem to={MainRoutes.CEXCreatingPage}>CEX</MenuItem>
    </PermissionWrapper>
  );

  const DEXCreate = (
    <PermissionWrapper key="DEXV2" abilityName={MainPagesAbilities.DEXCreatingView}>
      <MenuItem
        to={`${getRoutePath({ layer: "main", params: { section: "DEXCreatingPage" } })}/:uuid?/`}
      >
        DEX
      </MenuItem>
    </PermissionWrapper>
  );

  const createItems = filterBoolean([CEXCreate, DEXCreate]);

  const createMenu = createItems.length > 0 && <SubMenu title="Create bot">{createItems}</SubMenu>;

  return createMenu;
};

const useReportMenu = () => {
  const reportMenu = (
    <PermissionWrapper abilityName={MainPagesAbilities.BugReport}>
      <MenuItem>
        <styles.MenuLink href={BUG_REPORT_LINK} target="_blank" rel="noreferrer">
          Report Bug & Issue
        </styles.MenuLink>
      </MenuItem>
    </PermissionWrapper>
  );

  return reportMenu;
};

export interface UserMenuProps {
  themeToggler: () => void;
  onLogOut: () => void;
  onUpdAbilities: () => Promise<void>;
}

export const UserMenu = observer(({ themeToggler, onLogOut, onUpdAbilities }: UserMenuProps) => {
  const CreateMenu = useCreateMenu();
  const ReportMenu = useReportMenu();
  const togglePrimaryColor = useTogglePrimaryColor();

  return (
    <styles.Container>
      <styles.Navigation>
        <styles.MenuList>{CreateMenu}</styles.MenuList>
      </styles.Navigation>

      <styles.Separator />

      <styles.MenuList>
        <MenuItem onClick={themeToggler}>Switch theme</MenuItem>
        <MenuItem onClick={togglePrimaryColor}>Switch main color</MenuItem>
        <styles.Update onClick={onUpdAbilities}>Update abilities</styles.Update>
        <styles.Logout onClick={onLogOut}>Logout</styles.Logout>
      </styles.MenuList>

      <styles.Separator />

      <styles.MenuList>{ReportMenu}</styles.MenuList>
    </styles.Container>
  );
});
