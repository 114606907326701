import {
  DashboardVersion,
  getDashboardVersion,
} from "src/components/PartyPages/Dashboard/shared/config/versions";
import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { useQueryUrlParams } from "src/hooks/useQueryUrlParams";
import { useSubscribableStore } from "src/hooks/useSubscribableStore";
import CEXDashboardStore, { IDashboardState } from "src/state/CEX/CEXDashboard/v1";
import { CEXDashboardV2Store, IDashboardV2State } from "src/state/CEX/CEXDashboard/v2";

type DashboardState =
  | { state: IDashboardState; version: "v1" }
  | { state: IDashboardV2State; version: "v2" };

const useDashboardState = (dashboardVersion?: DashboardVersion) => {
  const version = dashboardVersion || getDashboardVersion();
  const stateConstructor =
    !dashboardVersion && version === "v1" ? CEXDashboardStore : CEXDashboardV2Store;
  const searchParamsProps = useQueryUrlParams();

  const state = useLocalStore(stateConstructor, searchParamsProps);
  return { state, version } as DashboardState;
};

export const DashboardContext = createLateInitContext<DashboardState>();

type DashboardProviderProps = ChildrenProps & { dashboardVersion?: DashboardVersion };

export const DashboardProvider = ({ dashboardVersion, children }: DashboardProviderProps) => {
  const state = useDashboardState(dashboardVersion);

  useSubscribableStore(state.state);

  return <DashboardContext.Provider value={state}>{children}</DashboardContext.Provider>;
};
