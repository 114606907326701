import { Currency, Price, Token } from "@uniswap/sdk-core";
import { invariant } from "mobx-utils";
import { validatePath } from "src/state/DEXV2/DEXV2Swap/shared";
import { V3LensPool } from "./V3LensPool";

const validatePathPools = (path: Token[], pools: V3LensPool[], input: Token, output: Token) => {
  validatePath(path);

  invariant(path[0].equals(input), "PATH_INPUT");
  invariant(path[path.length - 1].equals(output), "PATH_OUTPUT");

  invariant(pools[0].involvesToken(input), "POOL_INPUT");

  invariant(pools[pools.length - 1].involvesToken(output), "POOL_OUTPUT");
};

export const validatePrice = <TInput extends Currency, TOutput extends Currency>(
  input: TInput,
  output: TOutput,
  price: Price<TInput, TOutput>
) => {
  invariant(input.equals(price.baseCurrency), "INPUT_PRICE_MISMATCH");

  invariant(output.equals(price.quoteCurrency), "OUTPUT_PRICE_MISMATCH");
};

export class V3LensRoute<TInput extends Currency, TOutput extends Currency> {
  readonly input: TInput;

  readonly output: TOutput;

  readonly tokenPath: Token[];

  readonly midPrice: Price<TInput, TOutput> | undefined;

  readonly pools: V3LensPool[];

  constructor(
    path: Token[],
    pools: V3LensPool[],
    input: TInput,
    output: TOutput,
    midPrice?: Price<TInput, TOutput>
  ) {
    validatePathPools(path, pools, input.wrapped, output.wrapped);

    if (midPrice) {
      validatePrice(input, output, midPrice);
    }

    this.tokenPath = path;
    this.pools = pools;
    this.input = input;
    this.output = output;
    this.midPrice = midPrice;
  }
}
