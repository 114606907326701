import { CEXRoutes, DEXRoutes, DEXV2Routes, MainRoutes, PartyRoutes } from "src/app/constants";
import { LayerType } from "src/components/NavigationMenu";
import { DistributeLayerParams } from "./types";

export const BOT_TYPES = ["CEX", "DEX", "DEX_V2"] as const;

export type BotType = (typeof BOT_TYPES)[number];

export type CEXTabs = `${CEXRoutes}`;

export type DEXTabs = `${DEXRoutes}`;

export type DEXV2Tabs = `${DEXV2Routes}`;

export const PARTY_SECTION_PATH = "/party/:party/:section/";

type PartyPage = keyof typeof PartyRoutes;

export type BotTabs<T extends BotType> = T extends "CEX"
  ? CEXTabs
  : T extends "DEX"
  ? DEXTabs
  : T extends "DEX_V2"
  ? DEXV2Tabs
  : never;

export interface GetPartyLinkProps {
  party: string;
  partySection: PartyPage;
}

export interface GetBotLinkProps<T extends BotType> {
  party: string;
  botType: T;
  uuid: string;
  botTab?: BotTabs<T>;
}

export const getBotLink = <T extends BotType>({
  party,
  botType,
  uuid,
  botTab,
}: GetBotLinkProps<T>) => `/party/${party}/${botType}/${uuid}${botTab ? `/${botTab}` : ""}`;

export const getPartySectionLink = ({ party, partySection }: GetPartyLinkProps) =>
  `/party/${party}${PartyRoutes[partySection]}/`;

export const getRoutePath = ({ layer, params }: DistributeLayerParams<LayerType>) => {
  if (layer === "main") {
    const { section } = params;

    return `${MainRoutes[section]}`;
  }

  if (layer === "party") {
    const { section } = params;

    return `/party/:party${PartyRoutes[section]}/`;
  }

  if (layer === "bot") {
    const { botType } = params;

    return `/party/:party/${botType}/:uuid/:section`;
  }

  return MainRoutes.Root;
};

export const isBotType = (type: string): type is BotType => BOT_TYPES.includes(type as BotType);
