import { Currency, CurrencyAmount, Percent, Price, Token, TradeType } from "@uniswap/sdk-core";
import { Trade as BaseTrade } from "@uniswap/v2-sdk";
import { calculateNextPriceImpact } from "../../../../shared";
import { V2Pair } from "./V2Pair";
import { V2Route } from "./V2Route";

export class V2Trade<
  TInput extends Currency,
  TOutput extends Currency,
  TTradeType extends TradeType,
> extends BaseTrade<TInput, TOutput, TTradeType> {
  /**
   * The mid price after the trade executes assuming no slippage.
   */
  public readonly nextMidPrice: Price<TInput, TOutput>;

  /**
   * The percent difference between the mid price before the trade and the next mid price after the trade.
   */
  readonly nextPriceImpact: Percent;

  /**
   * Represents the path of tokens involved in a trade.
   */
  readonly path: Token[];

  constructor(
    route: V2Route<TInput, TOutput>,
    amount: TTradeType extends TradeType.EXACT_INPUT
      ? CurrencyAmount<TInput>
      : CurrencyAmount<TOutput>,
    tradeType: TTradeType
  ) {
    super(route, amount, tradeType);

    const nextPairs: V2Pair[] = new Array(route.pairs.length);
    const tokenAmounts: CurrencyAmount<Token>[] = new Array(route.path.length);

    if (tradeType === TradeType.EXACT_INPUT) {
      tokenAmounts[0] = amount.wrapped;
      for (let i = 0; i < route.path.length - 1; i += 1) {
        const pair = route.pairs[i];
        const [outputAmount, nextPair] = pair.getOutputAmount(tokenAmounts[i]);
        tokenAmounts[i + 1] = outputAmount;
        nextPairs[i] = nextPair;
      }
    } else {
      tokenAmounts[tokenAmounts.length - 1] = amount.wrapped;
      for (let i = route.path.length - 1; i > 0; i -= 1) {
        const pair = route.pairs[i - 1];
        const [inputAmount, nextPair] = pair.getInputAmount(tokenAmounts[i]);
        tokenAmounts[i - 1] = inputAmount;
        nextPairs[i - 1] = nextPair;
      }
    }

    this.nextMidPrice = new V2Route(nextPairs, route.input, route.output).midPrice;

    this.nextPriceImpact = calculateNextPriceImpact(route.midPrice, this.nextMidPrice);

    this.path = route.path;
  }
}
