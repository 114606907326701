import { object, string } from "yup";
import { UrlParamsSchema } from "src/state/shared/UrlSearchParams/types";
import { BooleanString } from "src/modules/shared/global";
import { BotsType } from "src/modules/bots";
import { UrlParams } from "./types";

const BOOLEAN_STRINGS: BooleanString[] = ["true", "false"];
const BOT_TYPES: BotsType[] = ["CEX", "DEX", "DEXV2"];

export const searchParamsValidationSchema: UrlParamsSchema<UrlParams> = object().shape({
  currentTab: string().oneOf(BOT_TYPES),
  redStatus: string().oneOf(BOOLEAN_STRINGS),
  yellowStatus: string().oneOf(BOOLEAN_STRINGS),
  blueStatus: string().oneOf(BOOLEAN_STRINGS),
  recentlyAddedEnabled: string().oneOf(BOOLEAN_STRINGS),
  selectedParties: string(),
  selectedExchanges: string(),
  selectedBase: string(),
  selectedChains: string(),
  selectedVersions: string(),
});
