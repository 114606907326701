import { Price, Token } from "@uniswap/sdk-core";
import { computed, makeAutoObservable, when } from "mobx";
import { computedFn } from "mobx-utils";
import { Pair, getPairs } from "src/api/dexScreener";
import { makeLoggable } from "src/helpers/logger";
import { cacheComparer, isWhenTimeoutError } from "src/helpers/mobx";
import { LogLevel, logDev, logDevError, logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import {
  IBotTradePairProvider,
  ISwapPairAddressProvider,
} from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import { IChainProvider } from "src/state/chain/ChainProviderStore";
import { IObservableCache } from "src/state/shared/Cache";
import { CacheOptions, tryParsePrice } from "../DEXV2Swap/shared";
import { AbstractStableCoin } from "../DEXV2Swap/shared/AbstractStableCoin";

export interface PairRawPrice {
  baseUsd: string;
  baseQuote: string;
}

export interface PairPrice {
  quote: { usd: Price<Token, Token> };
  base: { usd: Price<Token, Token>; quote: Price<Token, Token> };
}

export interface WaitOptions {
  waitTimeout?: number;
}

export interface GetTradePairPriceOptions extends CacheOptions, WaitOptions {}

export interface ITradePairPriceProvider extends IDisposable {
  get pairPrice(): PairPrice | undefined;
  get pairData(): Pair | undefined;
  getTradePairPrice(options?: GetTradePairPriceOptions): Promise<void>;
  get canQuery(): boolean;
}

export interface ITradePairPriceParams {
  chainProvider: IChainProvider;
  tradePairProvider: IBotTradePairProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  priceCacheStore: IObservableCache<PairRawPrice>;
}

const getPriceCacheKey = (chainId: number, pairAddress: string) => `pair-${chainId}-${pairAddress}`;

export class TradePairPriceProvider implements ITradePairPriceProvider {
  private _tradePairProvider: IBotTradePairProvider;

  private _pairAddressProvider: ISwapPairAddressProvider;

  private _chainProvider: IChainProvider;

  private _priceRawCacheStore: IObservableCache<PairRawPrice>;

  private _pairData?: Pair;

  private _loading = false;

  constructor({
    chainProvider,
    tradePairProvider,
    pairAddressProvider,
    priceCacheStore,
  }: ITradePairPriceParams) {
    makeAutoObservable<this, "_priceUSDCacheStore" | "_getPairCachedRawPrice">(this, {
      _priceUSDCacheStore: false,
      _getPairCachedRawPrice: false,
    });

    this._chainProvider = chainProvider;

    this._tradePairProvider = tradePairProvider;

    this._pairAddressProvider = pairAddressProvider;

    this._priceRawCacheStore = priceCacheStore;

    makeLoggable(this, { pairPrice: true });
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private get _pairAddress() {
    return this._pairAddressProvider.pairAddress;
  }

  private get _chainId() {
    return this._chainProvider.chainID;
  }

  private get _chainInfo() {
    return this._chainProvider.currentChain;
  }

  private get _screenerNetwork() {
    return this._chainInfo?.dexscreenerName;
  }

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  private get _priceCacheKey() {
    const chainId = this._chainId;
    const pairAddress = this._pairAddress;
    if (!chainId || !pairAddress) return null;

    const key = getPriceCacheKey(+chainId, pairAddress);

    return key;
  }

  private _setCachedRawPrice = (price: PairRawPrice, cacheKey: string) => {
    this._priceRawCacheStore.set(cacheKey, price);
  };

  private get _cachedRawPrice() {
    const cacheKey = this._priceCacheKey;
    if (!cacheKey) return undefined;

    return this._priceRawCacheStore.get(cacheKey);
  }

  private _getPairCachedRawPrice = computedFn(
    (cacheKey) => {
      if (!cacheKey) return undefined;

      return this._priceRawCacheStore.get(cacheKey);
    },
    {
      equals: cacheComparer<PairRawPrice>(),
    }
  );

  get pairPrice() {
    const tradePair = this._tradePair;
    const cacheKey = this._priceCacheKey;
    const rawPrice = this._getPairCachedRawPrice(cacheKey);
    if (!tradePair || !rawPrice) {
      return undefined;
    }

    const { base: baseToken, quote: quoteToken } = tradePair;

    const { baseQuote: baseQuotePrice, baseUsd: baseUsdPrice } = rawPrice;

    const priceStableCoin = new AbstractStableCoin(baseToken.chainId);

    // usd/base price
    const baseTokenUsdPrice = tryParsePrice(baseUsdPrice, priceStableCoin, baseToken);

    // quote/base price
    const baseTokenQuotePrice = tryParsePrice(baseQuotePrice, quoteToken, baseToken);

    if (!baseTokenUsdPrice || !baseTokenQuotePrice) {
      return undefined;
    }

    // quote in usd price = base/quote*usd/base
    const quoteTokenUsdPrice = baseTokenQuotePrice.invert().multiply(baseTokenUsdPrice);

    return {
      quote: { usd: quoteTokenUsdPrice },
      base: { quote: baseTokenQuotePrice, usd: baseTokenUsdPrice },
    };
  }

  get pairData() {
    return this._pairData;
  }

  private get _pairInfoDeps() {
    const pairAddress = this._pairAddress;
    const screenerNetwork = this._screenerNetwork;

    if (!pairAddress || !screenerNetwork) return null;
    return { pairAddress, screenerNetwork };
  }

  private _getPairInfo = async () => {
    const pairDeps = this._pairInfoDeps;

    if (!pairDeps) return;
    const { screenerNetwork, pairAddress } = pairDeps;

    const { isError, data } = await getPairs(screenerNetwork, pairAddress);
    if (!isError) {
      const pair = data.pairs?.[0];

      this._setPairData(pair);

      return pair;
    }
    return null;
  };

  private _setPairData(pair: Pair | undefined) {
    this._pairData = pair;
  }

  private _getBaseUsdPrice = (pair: Pair) =>
    // priceUsd = base in USD
    pair.priceUsd;

  private _getBaseQuotePrice = (pair: Pair) => {
    // priceNative = base in quote
    const { priceNative: priceBaseQuote, liquidity } = pair;

    if (priceBaseQuote) {
      return priceBaseQuote;
    }
    // try to get base in quote based on liquidity if no priceNative present
    // can't determine base in quote price
    if (!liquidity) {
      return;
    }
    const { base, quote } = liquidity;
    const midPriceBaseQuote = quote / base;
    // using sufficiently large constant to not lose precision on low value tokens
    return midPriceBaseQuote.toFixed(30);
  };

  private _getPairRawPrice = async (): Promise<PairRawPrice | undefined> => {
    const pair = await this._getPairInfo();
    if (!pair) return;

    const { priceUsd, priceNative, liquidity, volume } = pair;
    logDev(["_getPairUsdPrice", priceUsd, priceNative, liquidity, volume]);

    const baseUsdPrice = this._getBaseUsdPrice(pair);
    const baseQuotePrice = this._getBaseQuotePrice(pair);
    if (!baseUsdPrice || !baseQuotePrice) return;

    return { baseUsd: baseUsdPrice, baseQuote: baseQuotePrice };
  };

  private _getCachedRawPrice = async (useCache: boolean = true) => {
    if (useCache) {
      const cachedPrice = this._cachedRawPrice;

      if (cachedPrice) return cachedPrice;
    }

    const price = await this._getPairRawPrice();
    return price;
  };

  get canQuery() {
    return Boolean(this._pairInfoDeps);
  }

  private async _getTradePairPrice(options: CacheOptions = {}) {
    const cacheKey = this._priceCacheKey;
    if (!cacheKey) return;

    const rawPrice = await this._getCachedRawPrice(options?.useCache);
    if (!rawPrice) return;

    this._setCachedRawPrice(rawPrice, cacheKey);
  }

  private _getWaitTradePairPrice = async (options: GetTradePairPriceOptions = {}) => {
    try {
      const { waitTimeout, ...otherOptions } = options;
      if (waitTimeout) {
        await when(() => this.canQuery, { timeout: waitTimeout });
      }
      await this._getTradePairPrice(otherOptions);
    } catch (err) {
      // possible we can reach timeout waiting for deps to settle
      // log warning without showing error to ui
      if (isWhenTimeoutError(err)) {
        logDevError("Timeout when waiting for getTradePairPrice, no pair info will be fetched!", {
          level: LogLevel.Warning,
        });
      } else {
        throw err;
      }
    }
  };

  async getTradePairPrice(options: GetTradePairPriceOptions = {}) {
    if (this._loading) return;

    this._setLoading(true);
    try {
      await this._getWaitTradePairPrice(options);
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  }

  destroy = () => {};
}
