import { ChildrenProps } from "src/helpers/utils";
import { useChainProvider } from "src/hooks/useChainProvider";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { useAppState } from "src/state";
import DEXV2BotStore from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import { TradePairQuotesCalculator } from "src/state/DEXV2/Providers/TradePairQuotesCalculator";
import { TradePairQuotesContext } from "../TradePairQuotesContext";

export const DEXV2BotContext = createLateInitContext<DEXV2BotStore>();

type DEXV2BotProviderProps = ChildrenProps;

export const DEXV2BotProvider = ({ children }: DEXV2BotProviderProps) => {
  const chainProvider = useChainProvider();
  const { chainsInfoState, allBotsState } = useAppState();
  const { dexV2BotsState } = allBotsState;

  const botsStore = useLocalStore(DEXV2BotStore, {
    chainProvider,
    chainMetaProvider: chainsInfoState,
    cacheProvider: dexV2BotsState.cacheProvider,
  });

  return <DEXV2BotContext.Provider value={botsStore}>{children}</DEXV2BotContext.Provider>;
};
