import "./config/chartjs";
import "./config/dayjsConfig"; // Note: configs should be imported firstly (side-effects)
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { httpClientIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import React, { ReactNode } from "react";
import { render } from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./app/App";
import "./app/index.css";
import AppContext from "./context";
import { googleClientId, isDevEnv } from "./environment/env";
import { appState } from "./state";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: !isDevEnv(),
  integrations: [
    httpClientIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
      maskAllInputs: true,
    }),
  ],
  sendDefaultPii: true, // this will show api address with error
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 1.0, // 0.1 - This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // 0.1 - If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// fixes bug with child elements for QueryClientProvider
declare module "react-query/types/react/QueryClientProvider" {
  interface QueryClientProviderProps {
    children?: ReactNode;
  }
}

render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={appState}>
          <GoogleOAuthProvider clientId={googleClientId}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </GoogleOAuthProvider>
        </AppContext.Provider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
