import { getRoutePath } from "../shared/MenuList/utils";

interface NavPath {
  path: string;
  botType: "CEX" | "DEX" | "DEX_V2";
}

export const BOT_NAV_PARAMS: NavPath[] = [
  { path: getRoutePath({ layer: "bot", params: { botType: "CEX" } }), botType: "CEX" },
  { path: getRoutePath({ layer: "bot", params: { botType: "DEX" } }), botType: "DEX" },
  { path: getRoutePath({ layer: "bot", params: { botType: "DEX_V2" } }), botType: "DEX_V2" },
];
