import { Suspense } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { AllBots } from "src/components/AllBots";
import { PartyQueryParams } from "src/components/NavigationMenu/MenuContent/PartyNavigation";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { Loader } from "src/components/shared/Loader";
import { PermissionWrapper } from "src/components/shared/PermissionWrapper";
import { AllBotsContext } from "src/context/AllBots";
import { BotModulesProvider } from "src/context/CEX/BotModules/BotModulesProvider";
import { DashboardProvider } from "src/context/CEX/Dashboard";
import { ExpertSystemProvider } from "src/context/CEX/ExpertSystem";
import { FundingProvider } from "src/context/CEX/FundingProvider";
import { lazily } from "src/helpers/lazily";
import { useConnectPartyAbilities } from "src/hooks/abilities/useConnectPartyAbilities";
import useLocalStore from "src/hooks/useLocalStore";
import { useAppState } from "src/state";
import { AllBotsStore } from "src/state/AllBots";
import { getRoutePath } from "../../components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { PartyPagesAbilities } from "../constants";

const { Dashboard, DashboardV3 } = lazily(() => import("src/components/PartyPages/Dashboard"));
const { MultiGrid } = lazily(() => import("src/components/PartyPages/MultiGrid"));
const { BotModules } = lazily(() => import("src/components/PartyPages/BotModules"));
const { Analytics } = lazily(() => import("src/components/PartyPages/Analytics"));
const { CEXFunding } = lazily(() => import("src/components/PartyPages/CEXFunding"));
const { ExpertSystem } = lazily(() => import("src/components/PartyPages/ExpertSystem"));
const { MarketsDetailedInfo } = lazily(() => import("src/components/MarketsDetailedInfo"));

export const PartyRouter = () => {
  const { chainsInfoState } = useAppState();
  const { party } = useParams<PartyQueryParams>();

  const state = useLocalStore(AllBotsStore, chainsInfoState, party);

  useConnectPartyAbilities(party);

  return (
    <Suspense fallback={<Loader show />}>
      <Switch>
        <Route path={getRoutePath({ layer: "party", params: { section: "Bots" } })}>
          <ErrorBoundary>
            <AllBotsContext.Provider value={state}>
              <AllBots />
            </AllBotsContext.Provider>
          </ErrorBoundary>
        </Route>

        <Route path={getRoutePath({ layer: "party", params: { section: "Dashboard" } })}>
          <PermissionWrapper abilityName={PartyPagesAbilities.DashboardView} showInfoMsg>
            <ErrorBoundary>
              <DashboardProvider>
                <Dashboard party={party} />
              </DashboardProvider>
            </ErrorBoundary>
          </PermissionWrapper>
        </Route>

        <Route path={getRoutePath({ layer: "party", params: { section: "DashboardV2" } })}>
          <PermissionWrapper abilityName={PartyPagesAbilities.DashboardAccountingView} showInfoMsg>
            <ErrorBoundary>
              <DashboardV3 party={party} />
            </ErrorBoundary>
          </PermissionWrapper>
        </Route>

        <Route path={getRoutePath({ layer: "party", params: { section: "Multigrid" } })}>
          <PermissionWrapper abilityName={PartyPagesAbilities.MultigridView} showInfoMsg>
            <ErrorBoundary>
              <MultiGrid party={party} abilityName={PartyPagesAbilities.MultigridEdit} />
            </ErrorBoundary>
          </PermissionWrapper>
        </Route>

        <Route path={getRoutePath({ layer: "party", params: { section: "Modules" } })}>
          <PermissionWrapper abilityName={PartyPagesAbilities.ModulesView} showInfoMsg>
            <ErrorBoundary>
              <BotModulesProvider>
                <BotModules party={party} abilityName={PartyPagesAbilities.ModulesEdit} />
              </BotModulesProvider>
            </ErrorBoundary>
          </PermissionWrapper>
        </Route>

        <Route path={getRoutePath({ layer: "party", params: { section: "Analytics" } })}>
          <PermissionWrapper abilityName={PartyPagesAbilities.CandleAnalyticsView} showInfoMsg>
            <ErrorBoundary>
              <Analytics party={party} />
            </ErrorBoundary>
          </PermissionWrapper>
        </Route>

        <Route path={getRoutePath({ layer: "party", params: { section: "Funding" } })}>
          <PermissionWrapper abilityName={PartyPagesAbilities.FundingView} showInfoMsg>
            <ErrorBoundary>
              <FundingProvider>
                <CEXFunding party={party} abilityName={PartyPagesAbilities.FundingEdit} />
              </FundingProvider>
            </ErrorBoundary>
          </PermissionWrapper>
        </Route>

        <Route path={getRoutePath({ layer: "party", params: { section: "Strategies" } })}>
          <PermissionWrapper abilityName={PartyPagesAbilities.StrategiesView} showInfoMsg>
            <ExpertSystemProvider>
              <ErrorBoundary>
                <ExpertSystem party={party} abilityName={PartyPagesAbilities.StrategiesEdit} />
              </ErrorBoundary>
            </ExpertSystemProvider>
          </PermissionWrapper>
        </Route>

        <Route path={getRoutePath({ layer: "party", params: { section: "MarketsDetailed" } })}>
          <PermissionWrapper abilityName={PartyPagesAbilities.MarketCheckDetailedView} showInfoMsg>
            <ErrorBoundary>
              <MarketsDetailedInfo />
            </ErrorBoundary>
          </PermissionWrapper>
        </Route>
      </Switch>
    </Suspense>
  );
};
