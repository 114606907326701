import { Currency, CurrencyAmount, Price, Token, TradeType } from "@uniswap/sdk-core";
import { BigNumber } from "ethers";
import { logDev } from "src/helpers/network/logger";
import { Q192 } from "src/state/DEXV2/DEXV2Swap/shared";
import { IGetRouteStateParams, IRouteStateProvider } from "../../../../shared/Swap/Router";
import { V3LensRoute } from "../../entities/lens/V3LensRoute";
import { V3LensTrade } from "../../entities/lens/V3LensTrade";
import { LensError } from "../../error/LensError";
import { QuotesError } from "../../error/QuotesError";
import { IV3LensQuotesProvider } from "./V3LensQuotesProvider";
import { IV3LensRoutesProvider } from "./V3LensRoutesProvider";

export enum V3LensSwapRouteError {
  LensError = "LensError",
}

export type V3LensSwapRoute = {
  trade: V3LensTrade<Token, Token, TradeType>;

  route: V3LensRoute<Token, Token>;
};

type TradeAmounts<TInput extends Currency, TOutput extends Currency> = {
  amountIn: CurrencyAmount<TInput>;
  amountOut: CurrencyAmount<TOutput>;
};

export interface IV3LensRouteStateParams {
  routesProvider: IV3LensRoutesProvider;
  quotesProvider: IV3LensQuotesProvider;
}

export class V3LensRouteStateProvider implements IRouteStateProvider {
  private _routesProvider: IV3LensRoutesProvider;

  private _quotesProvider: IV3LensQuotesProvider;

  constructor({ routesProvider, quotesProvider }: IV3LensRouteStateParams) {
    this._routesProvider = routesProvider;
    this._quotesProvider = quotesProvider;
  }

  private _getAmounts = <TInput extends Currency, TOutput extends Currency>(
    route: V3LensRoute<TInput, TOutput>,
    amount: CurrencyAmount<TInput | TOutput>,
    tradeType: TradeType,
    quote: BigNumber
  ): TradeAmounts<TInput, TOutput> => {
    const quoteAmount = quote.toString();

    if (tradeType === TradeType.EXACT_INPUT) {
      const amountIn = amount as CurrencyAmount<TInput>;

      const amountOut = CurrencyAmount.fromRawAmount(route.output, quoteAmount);

      return { amountIn, amountOut };
    }

    const amountOut = amount as CurrencyAmount<TOutput>;

    const amountIn = CurrencyAmount.fromRawAmount(route.input, quoteAmount);

    return { amountIn, amountOut };
  };

  private _getNextMidPrice = <TInput extends Currency, TOutput extends Currency>(
    route: V3LensRoute<TInput, TOutput>,
    sqrtPriceX96After?: BigNumber
  ) => {
    if (!sqrtPriceX96After) return undefined;

    const tokenIn = route.input;
    const pool = route.pools[0];
    const { token0 } = pool;
    const { token1 } = pool;

    // price = token1/token0 = (sqrtPriceX96/2^96)^2
    const priceAfter = new Price(
      token0,
      token1,
      Q192.toString(),
      sqrtPriceX96After.mul(sqrtPriceX96After).toString()
    );

    return (!tokenIn.equals(token0) ? priceAfter.invert() : priceAfter) as Price<TInput, TOutput>;
  };

  private _getTrade = async <TInput extends Currency, TOutput extends Currency>(
    route: V3LensRoute<TInput, TOutput>,
    amount: CurrencyAmount<TInput | TOutput>,
    swapType: TradeType
  ) => {
    const quoteResult = await this._quotesProvider.getQuote(route, amount, swapType);

    if (!quoteResult) return null;

    const nextMidPrice = this._getNextMidPrice(route, quoteResult.sqrtPriceX96After);

    logDev(["lensV3: nextMidPrice", nextMidPrice?.toFixed()]);

    const quote = quoteResult.amountOut;

    logDev(["lensV3: quote", quote.toString()]);

    const tradeAmount = this._getAmounts(route, amount, swapType, quote);

    const trade = new V3LensTrade({
      route,
      inputAmount: tradeAmount.amountIn,
      outputAmount: tradeAmount.amountOut,
      tradeType: swapType,
      nextMidPrice,
    });

    return trade;
  };

  getRouteState = async ({ path, amount, swapType, options }: IGetRouteStateParams) => {
    try {
      const route = await this._routesProvider.getRoute(path, options);
      if (!route) return null;

      const trade = await this._getTrade(route, amount, swapType);

      if (!trade) return null;

      return { trade, route };
    } catch (err) {
      if (err instanceof QuotesError === false) {
        throw new LensError("Failed to get lens route");
      }
      return null;
    }
  };
}
