import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { MenuList } from "../../shared/MenuList";
import { BotQueryParams, IMenuItem } from "../../shared/MenuList/MenuItem/types";
import { BotType } from "../../shared/MenuList/utils";
import { useLoadIndication } from "../../shared/hooks";
import { AdditionalCEXMenuItems, CEXMenuItems } from "./MenuItems/CEXMenuItems";
import { DEXMenuItems } from "./MenuItems/DEXMenuItems";
import { DEXV2MenuItems } from "./MenuItems/DEXV2MenuItems";
import { filterWithAbilitiesMenu } from "../../shared/utils";

export interface BotContentMenuItem<T extends string> extends Omit<IMenuItem<"bot">, "to"> {
  value: T;
  to: (params: BotQueryParams) => string;
}

const MENU_ITEMS_MAP: Record<BotType, IMenuItem<"bot">[]> = {
  CEX: CEXMenuItems,
  DEX: DEXMenuItems,
  DEX_V2: DEXV2MenuItems,
};

const ADDITIONAL_MENU_ITEMS_MAP: Record<BotType, IMenuItem<"bot">[]> = {
  CEX: AdditionalCEXMenuItems,
  DEX: [],
  DEX_V2: [],
};

export interface BotNavigationProps {
  botType: "CEX" | "DEX" | "DEX_V2";
}

const useGetCurrentTab = () => {
  const { section } = useParams<{ section: string }>();

  return section;
};

export const useGetPartyCurrentTab = () => {};

const useGetPathParams = () => {
  const queryParams = useParams<BotQueryParams>();

  return queryParams;
};

export const BotNavigationList = observer(({ botType }: BotNavigationProps) => {
  const menuItems = MENU_ITEMS_MAP[botType];
  const additionalItems = ADDITIONAL_MENU_ITEMS_MAP[botType];

  const { party, uuid } = useGetPathParams();
  const currentTab = useGetCurrentTab();

  const fullMenuList = useMemo(
    () => filterWithAbilitiesMenu([...menuItems, ...additionalItems], party),
    [party, menuItems, additionalItems]
  );

  useLoadIndication({ party, uuid, currentTab, menuList: fullMenuList });

  return (
    <MenuList<"bot">
      useGetCurrentTab={useGetCurrentTab}
      useGetPathParams={useGetPathParams}
      menuItems={menuItems}
      additionalItems={additionalItems}
    />
  );
});
